
import '@fontsource/khula/400.css';
import '@fontsource/khula/600.css';
import '@fontsource/league-gothic';
import "@fontsource/league-gothic/400.css";
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import React from "react";
import PortfolioWrapper from './src/components/_pages/portfolio/PortfolioWrapper';


export const wrapPageElement = ({ element, props }) => {
  // console.log('props:', props)
  // console.log('element:', element)

  if (props.location.pathname.startsWith('/portfolio/') && element.type.isMDXComponent === true) {
    return <PortfolioWrapper {...props}>{element}</PortfolioWrapper>
  }
}