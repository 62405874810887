import React from 'react'
import "../../styles/components/_grain.scss"
import grainImg from "./paper2.png";

function AnimatedGrain() {
  return (
    <div className="background">
      {/*
        <style
          dangerouslySetInnerHTML={{
            __html: [
              ".background::after {",
              ` background-image: url(${grainImg})`,
              "}",
            ].join("\n"),
          }}
        ></style>
      */}
    </div>
  )
}

export default AnimatedGrain
