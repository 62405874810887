import React from 'react'

function TopBarLoader() {
  // creates a 100vw wide div with a 3px height and a gradient that changes color from red to yellow to green 

  const style = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: 3,
    background: 'linear-gradient(to right, #f44336, #ff9800, #4caf50)',
    zIndex: 9999,
    userSelect: 'none',
    pointerEvents: 'none',
    animation: "hue 1s infinite linear",
  }

  return <div style={style} />
}

export default TopBarLoader;