import { SEO } from "@components/seo";
import { globalHistory as history } from "@reach/router";
import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { isMobile } from "react-device-detect";
import "../../../styles/pages/_portfolio.scss";
import Cursor from "../../Cursor/cursor";
import Section from "../../_SectionTemplate";
import Layout from "../../layout";
import PortfolioWrapperContent from "./PortfolioWrapperContent";

function PortfolioWrapper(props) {
  // const title = props.pageContext.frontmatter.title;
  const { location } = history;

  useEffect(() => {
    // console.log("props", props);
    // console.log("data", props.data);
  });

  if (typeof window === `undefined` || typeof document === `undefined`) {
    return <div></div>;
  }

  return (
    <>
      <Layout location={location}>
        {!isMobile && <Cursor />}
        {/* <Scroll callbacks={location} /> */}
        <SEO />

        <Row>
          <Col>
            <Section fluid desc="portfolio-wrapper" className="portfolio-wrapper" data-desc={props?.pageContext?.frontmatter?.title || "null"}>
              <div className="portfolio-wrapper-inner" data-scroll data-scroll-direction="horizontal">
                <PortfolioWrapperContent
                  title={props?.pageContext?.frontmatter?.title || "image"}
                />
              </div>
            </Section>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default PortfolioWrapper;
