//react component to import and play a music file

import React, { Suspense, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import music from "../../../static/music/Rain and Sunshine cmprsd.mp3";
import "../../styles/components/MusicPlayer.scss";
import musicJson from './music.json';

export default function MusicPlayer() {

  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false); 

  useEffect(() => {
    const audio = new Audio(music);
    setAudio(audio);
  }, []);

  //check if audio is playing when component mounts
  useEffect(() => {
    if (audio) {
      setIsPlaying(audio.currentTime > 0 && !audio.paused && !audio.ended && audio.readyState > 2);
    }
  }, [audio]);

  const togglePlay = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const onEnded = () => {
    setIsPlaying(false);
  };

  //use react suspense to lazy load audio
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="music-player">
        <div className="music-player__container">
          <div className="music-player__controls">
            <div className="music-player__play">
            <div className={`menu-circle music ${isPlaying ? "active" : ""}`}></div>
              {false && <Lottie
                onClick={togglePlay}
                loop
                animationData={musicJson}
                play={isPlaying}
                style={{ width: 30, height: 30 }}
              />}
            </div>
          </div>
        </div>
        <audio loop>
          <source src={music} type="audio/mpeg" />
        </audio>  
      </div>
    </Suspense>
  );
}

  // return (
  //   <div className="music-player">
  //     <button onClick={() => togglePlay()}>
  //       {isPlaying ? 'Pause' : 'Play'}
  //     </button>
  //     <audio autoPlay={isPlaying} onEnded={onEnded} loop>
  //       <source src={music} type="audio/mpeg" />
  //     </audio>      
  //   </div>
  // )
// }
