import React, { useEffect } from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

/**
 * This component renders a slot with optional body (header, title, text, etc.) contents spanning 1 grid column.
 * If plpURL prop is provided, the plp will link to an external page
 */

function ImageVideo(props) {
  
  useEffect(() => {
    // console.log('ImageVideo props: ', props)
  }, [])
  
  const bodyContent = <div className={`content-wrapper`}>
    <div className="media">
      { (props.imageSrc && !props.videoSrc) && 
        <img
          src={props.imageSrc.publicURL}
          alt={props.alt}
          loading="lazy"
        />
      }
      { props.videoSrc && 
        <video width="100%" autoPlay loop muted>
          <source src={props.videoSrc} type="video/mp4" />
        </video>
      }
    </div>
    <div className="content">
      { props.children && props.children }  
    </div>
  </div>;


  return <div className={`p-hero 
    ${props.videoSrc ? "video" : ""} 
    ${props.caption ? "with-caption":"no-caption"}`}
    data-scroll data-scroll-speed="-0.63" data-scroll-delay="0.07"
  >
    {bodyContent}
    {props.caption && <span className="caption">{props.caption}</span>}
  </div>
}

export default ImageVideo