import { globalHistory as history } from "@reach/router";
import { Link } from "gatsby";
import { KBarProvider } from "kbar";
import PropTypes from "prop-types";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ThemeContext from "../../context/ThemeContext";
import "../../styles/components/_header.scss";
import Animate from "../Animate";
import CommandBar, { actions } from "../CommandBar";
import MusicPlayer from "../MusicPlayer";
import StoriesCircle from "../__stories/StoriesCircle";
import { windowUndefinedReturnNull } from "../_helpers/helpers";
import TopBarLoader from "./../TopBarLoader";
const FullScreenMenu = React.lazy(() => import( /* webpackChunkName: "Tonye Menu" */"../FullScreenMenu/FullScreenMenu"));
const Header = ({
  leftLogo,
  siteTitle
}) => {
  const [scrollingUp, setScrollingUp] = useState(false);
  const [scrollingDown, setScrollingDown] = useState(false);
  const [floatHeader, setFloatHeader] = useState(false);
  const [headerOffset, setHeaderOffset] = useState(130);
  const [headerWasHidden, setHeaderWasHidden] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [typewriter, setTypewriter] = useState(false);
  const [size, setSize] = useState(typeof window !== "undefined" ? window.innerWidth : 0);
  const {
    location
  } = history;
  const isStoryPage = location.pathname.startsWith("/stories");
  const isBlogPage = location.pathname.startsWith("/blog");
  const isHomePage = location.pathname === "/";
  const isBlogSubPage = location.pathname.startsWith("/blog/") && location.pathname.length > 6;
  const showStoriesCircle = !isBlogSubPage;
  const prevScrollY = useRef(0);
  const toggleMenu = value => {
    if (value) {
      setMenuOpen(value);
      lockScroll(value);
    } else {
      setMenuOpen(prevState => !prevState);
      lockScroll(menuOpen);
    }
  };
  const lockScroll = (value, reset) => {
    // if (value || reset === "reset") {
    //   document.body.style.overflow = "auto";
    //   document.documentElement.style.overflow = "auto";
    // } else {
    //   document.body.style.overflow = "hidden";
    //   document.documentElement.style.overflow = "hidden";
    // }
  };
  useEffect(() => {
    windowUndefinedReturnNull();
    const handleResize = () => {
      setSize(window.innerWidth);
      lockScroll(null, "reset");
    };
    const handleNavigation = () => {
      const windowScrollY = window.scrollY;
      if (windowScrollY > headerOffset) {
        setHeaderWasHidden(true);
      } else {
        setHeaderWasHidden(false);
      }
      if (prevScrollY.current > windowScrollY) {
        setScrollingUp(true);
        setScrollingDown(false);
        floatHeaderTo("up", windowScrollY);
      } else if (prevScrollY.current < windowScrollY) {
        setScrollingUp(false);
        setScrollingDown(true);
        floatHeaderTo("down", windowScrollY);
      }
      prevScrollY.current = windowScrollY;
    };
    window.addEventListener("scroll", handleNavigation);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
      window.removeEventListener("resize", handleResize);
      lockScroll(null, "reset");
    };
  }, []);
  const floatHeaderTo = (direction, scrollY) => {
    if (isHomePage || scrollY < headerOffset || headerWasHidden) {
      return;
    }
    if (direction === "up" && window.pageYOffset > headerOffset) {
      setFloatHeader(true);
    }
    if (direction === "up" && window.pageYOffset < headerOffset || direction === "down" && window.pageYOffset > headerOffset) {
      setFloatHeader(false);
    }
  };
  const path = () => {
    const {
      location
    } = history;
    if (location.pathname === "/") {
      return "glass";
    } else {
      return "morph";
    }
  };
  const handleTypewriter = value => {
    setTypewriter(value);
  };
  const activePage = page => {
    const {
      location
    } = history;
    if (location.pathname.startsWith("/" + page)) {
      return "active";
    } else {
      return null;
    }
  };

  // console.log("location", location)

  console.log("menuOpen", menuOpen);
  if (typeof window === "undefined") {
    return null;
  }
  return <ThemeContext.Consumer>
      {theme => <>
          <KBarProvider actions={actions}>
            <header className={` header 
              ${floatHeader && "fade-in-top float-header"}
              ${scrollingDown && window.pageYOffset > headerOffset && !menuOpen && !isHomePage ? "float-header-bye fade-out-top" : "header-offset"}
              ${!isBlogPage && !isStoryPage ? "nb" : "b"}
              ${theme.color} 
              ${path()}
              ${menuOpen ? "menu-open" : ""}
            `}>
              <Row>
                <Col xs={3} className={`text-left tonye fade-in ${leftLogo ? "tonye-left" : "tonye-top"}`}>
                  <Link to="/" className={`logo-ctr ${location.pathname !== "/" ? "active" : ""}`} onMouseOver={() => handleTypewriter(true)} onMouseOut={() => handleTypewriter(false)}>
                    <StoriesCircle home img="/images/tonye_gradient.png" text="" url="/Stories" watched={false} inHeader={true} visible={showStoriesCircle} />
                    {size >= 768 && <Link to="/" className="wipe" />}
                    {false && location.pathname !== "/" && <i className={`dotcom ${typewriter ? "typewriter" : ""}`}>.com</i>}
                  </Link>
                </Col>
                <Col xs={9} className={`tonye nav-links text-right ${theme.menuExpanded ? "expanded-header-links" : ""}`}>
                  <ul className={!isHomePage ? "nhp" : null}>
                    {location.pathname !== "/" && !menuOpen && <li className="nav-links-li">
                        <Link to="/about" className={`nav-link ${activePage("about")}`} activeClassName="active-link">
                          About
                        </Link>
                        <Link to="/blog" className={`nav-link ${activePage("blog")}`} activeClassName="active-link">
                          Blog
                        </Link>
                        <Link to="/portfolio" className={`nav-link ${activePage("portfolio")}`} activeClassName="active-link">
                          Portfolio
                        </Link>
                        <Link to="/stories" className={`nav-link ${activePage("stories")}`} activeClassName="active-link">
                          Stories
                        </Link>
                        <Link to="/contact" className={`nav-link ${activePage("contact")}`} activeClassName="active-link">
                          Contact
                        </Link>
                      </li>}
                    <div className="menu-right">
                      <CommandBar />
                      <MusicPlayer />
                      <div className="stories-menu">
                        <li>
                          <div className={`menu-toggle`} onClick={() => toggleMenu()}>
                            <div className={`menu-circle menu ${menuOpen ? "active" : ""}`}></div>
                            {/* <div className="nav-but-wrap">
                             <div className={`menu-icon hover-target ${menuOpen ? "active" : ""}`}>
                              <div className={`icon-ctr ${menuOpen ? "active" : ""}`}>
                                <span className="menu-icon__line menu-icon__line-left"></span>
                                <span className="menu-icon__line"></span>
                                <span className="menu-icon__line menu-icon__line-right"></span>
                              </div>
                             </div>
                             </div> */}
                          </div>
                        </li>
                      </div>
                    </div>
                  </ul>
                </Col>
              </Row>
            </header>
          </KBarProvider>
          <>
            <Suspense fallback={<TopBarLoader text="Menu Incoming..." />}>
              <Animate show={menuOpen} className="tonyes-menu" animationDurationIn={0.6} animationDurationout={1} animationDelay={0} entranceAnimation="slide-in-bottom-menu" exitAnimation="slide-out-bottom-menu2" style={{
            position: "fixed"
          }}>
                <FullScreenMenu />
              </Animate>
            </Suspense>
          </>
        </>}
    </ThemeContext.Consumer>;
};
Header.propTypes = {
  siteTitle: PropTypes.string
};
Header.defaultProps = {
  siteTitle: `Tonye's Blog`
};
export default Header;