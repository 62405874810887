import React from "react";
const defaultState = {
  color: "dark",
  toggleTheme: () => {}
};
const ThemeContext = React.createContext(defaultState);

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () => typeof window !== "undefined" && window.matchMedia("(prefers-color-scheme: dark)").matches === true;
class ThemeProvider extends React.Component {
  targetElement = null;
  state = {
    color: "dark"
  };
  toggleTheme = color => {
    // let color = this.state.color
    localStorage.setItem("theme", JSON.stringify(color));
    this.setState({
      color: color
    });
  };
  componentDidMount() {
    // Getting dark mode value from localStorage!
    const lsDark = JSON.parse(localStorage.getItem("theme"));
    if (lsDark) {
      this.setState({
        color: lsDark
      });
    } else if (supportsDarkMode()) {
      this.setState({
        color: "dark"
      });
    }
    this.targetElement = document.querySelector('#___gatsby');
  }
  render() {
    // console.log("states prv", this.state);
    // console.log("props prv", this.props);

    const {
      children
    } = this.props;
    const {
      color
    } = this.state;
    return <ThemeContext.Provider value={{
      color,
      toggleTheme: this.toggleTheme
    }}>
        {children}
      </ThemeContext.Provider>;
  }
}
export default ThemeContext;
export { ThemeProvider };