import { globalHistory as history } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import "../../styles/components/_footer.scss";
import Section from "../_SectionTemplate";
const Footer = (): JSX.Element => {
  const {
    location
  } = history;

  //chcek if on blog page
  const isBlogPage = location.pathname.startsWith("/blog");
  const isAboutPage = location.pathname.startsWith("/about");
  const noSmoothScroll = typeof window !== "undefined" && window.scroll.scrollTo !== "function";

  //if location pathname doesn't start with "/blog/", and scroll.scrollTop function exists, use locomotive scroll.scrollTop function, else use native scroll top
  function locomotiveScrollToTop() {
    if (typeof window !== "undefined") {
      // if (isBlogPage && typeof window.scroll.scrollTo !== "function") {
      //   window.scrollTo({ top: 0, behavior: "smooth" });
      // }
      // else {
      // window?.scroll?.scrollTo ?
      // window?.scroll?.scrollTo("#home")
      // :
      // window.scrollTo({ top: 0, behavior: "smooth" });
      window.scroll.scrollTo ? window.scroll.scrollTo("#home") : window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
  return <Section desc={`footer ${noSmoothScroll && isBlogPage && "not-smooth"} ${isBlogPage && "blog"} ${isAboutPage && "about"}`} dss={false}>
      <div className="row" data-scroll data-scroll-speed="0">
        <footer className="footer-inner" data-scroll-target="#footer">
          {/* <div className="link-grid">
           <UserLinks includeRss />
           </div> */}
          <div className="info row justify-content-center align-items-center">
            <p className="col-sm-3 mb-0">Tonye Brown © {new Date().getFullYear()}</p>
            <p className="col-sm-6 row justify-content-center footer-links mb-0">
              {/* <Link to="/blog" noBasePath className="col-md-3 link ">Blog</Link> */}
              <Link to="/contact" noBasePath className="col-md-3 link ">
                Contact
              </Link>
              <a href="https://www.twitter.com/truevined" target="_blank" className="col-md-3 link ">
                Twitter
              </a>
              <a href="https://www.linkedin.com/in/tonyeb/" target="_blank" className="col-md-3 link ">
                LinkedIn
              </a>
            </p>
            <p className="col-sm-3 mb-0 scroll-top-ctr">
              <span className="scroll-top" onClick={() => locomotiveScrollToTop()}>
                Scroll to Top <b>↑</b>
              </span>
            </p>
          </div>
        </footer>
      </div>
    </Section>;
};
export default Footer;