import React, { Component } from "react";
import "../../styles/components/_cursor.scss";

class Cursor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseX: 0,
      mouseY: 0,
      trailingX: 0,
      trailingY: 0,
    };
    this.cursor = React.createRef();
    this.cursorTrailing = React.createRef();
    this.animationFrame = null;

    this.mouseIsHover = false;
    this.cursorHoverElems =
      "a, button, .initials, .navigation .item, .date, .expand-gallery, .la-chevron-circle-left, .la-chevron-circle-right, .la-times";

    this.dragon = false;
    this.dragElems = ".tonye-gallery span[draggable]";

    this.portfolio = false;
    this.portfolioElems = ".portfolio-list-img, .random-work-img";

    this.video = false;
    this.videoElems = ".demo-reel";
  }

  componentDidMount() {
    document.addEventListener("mousemove", this.onMouseMove);
    !isTouchDevice() && this.moveCursor();

    // add listener to track the current mouse hover
    document.addEventListener("mousemove", (e) => {
      //check for hover elems
      if (e.target.closest(this.cursorHoverElems)) {
        this.mouseIsHover = true;
      } else {
        this.mouseIsHover = false;
      }

      //check for drag elems
      if (e.target.closest(this.dragElems)) {
        this.dragon = true;
      } else {
        this.dragon = false;
      }

      //check for portfolio elems
      if (e.target.closest(this.portfolioElems)) {
        this.portfolio = true;
      } else {
        this.portfolio = false;
      }

      //check for demo reel
      if (e.target.closest(this.videoElems)) {
        this.video = true;
      } else {
        this.video = false;
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.onMouseMove);
    cancelAnimationFrame(this.animationFrame);
  }

  onMouseMove = (evt) => {
    const { clientX, clientY } = evt;
    this.setState({
      mouseX: clientX,
      mouseY: clientY,
    });
    this.cursorTrailing.current.style.opacity = "1";
  };

  getSkwish(posX, posY, mouseX, mouseY) {
    const normalX = Math.min(Math.floor((Math.abs(mouseX - posX) / posX) * 1000) / 1000, 1);
    const normalY = Math.min(Math.floor((Math.abs(mouseY - posY) / posY) * 1000) / 1000, 1);

    const normal = (normalX + normalY) / 2;
    const skwish = Math.min(normal * .5, 0.15);
    return skwish;
  }

  moveCursor = () => {
    const { mouseX, mouseY, trailingX, trailingY } = this.state;
    const diffX = mouseX - trailingX;
    const diffY = mouseY - trailingY;

    const angle = Math.atan2(mouseY, mouseX) * 45 / Math.PI;
    const skwish = this.getSkwish(mouseX, mouseY, mouseX, mouseY);

    //  Number in expression is coeficient of the delay. 10 for example. You can play with it.
    this.setState(
      {
        trailingX: trailingX + diffX / (this.dragon ? 1 : 7), //if dragging, reduce cursor delay
        trailingY: trailingY + diffY / (this.dragon ? 1 : 7),
      },
      () => {
        // Using refs and transform for better performance.
        this.cursor.current.style.transform = `translate3d(${mouseX - 100
          }px, ${mouseY}px, 0)`;
        this.cursorTrailing.current.style.display = "block";
        this.cursorTrailing.current.style.transform = `translate3d(${trailingX - 8
          }px, ${trailingY - 8}px, -1px)`;
        this.animationFrame = requestAnimationFrame(this.moveCursor);
      }
    );
  };

  render = () => {
    return (
      <div className="container">
        <div
          className={`cursors 
            ${this.mouseIsHover && "woop"}
            ${this.portfolio && "portfolio"}
            ${this.video && "video"}
          `}
        >
          <div className="cursor" ref={this.cursor} />
          <div
            className={`cursor-trailer 
              ${this.mouseIsHover && "woop"} 
              ${this.dragon && "drag"}
              ${this.portfolio && "portfolio"}
              ${this.video && "video"}
            `}
            ref={this.cursorTrailing}
          />
        </div>
      </div>
    );
  };
}

export default Cursor;

function isTouchDevice() {
  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if ("ontouchstart" in window || window.DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    ""
  );
  return mq(query);
}