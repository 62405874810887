//if location pathname doesn't start with "/blog/", and scroll.scrollTop function exists, use locomotive scroll.scrollTop function, else use native scroll top 
export function vanillaLocomotiveScrollToTop(location) {
  if (typeof window !== "undefined") {
    if (location.pathname.startsWith("/blog/") && typeof window.scroll.scrollTo !== "function") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    else {
      window.scroll?.scrollTo ?
        window.scroll.scrollTo("#home")
        :
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
}

/**
 * 
 * @param { Number of lines/arrays to return } N 
 * @param { String to split } string 
 * @returns 
 */
export function splitStringToArray(N, string) {
  let app = string.split(' '),
    arrayApp = [],
    stringApp = "";
  app.forEach(function (sentence, index) {
    stringApp += sentence + ' ';

    if ((index + 1) % N === 0) {
      arrayApp.push(stringApp);
      stringApp = '';
    }
    else if (app.length === index + 1 && stringApp !== '') {
      arrayApp.push(stringApp);
      stringApp = '';
    }
  });
  // console.log(arrayApp);
  return arrayApp;
};


// react function to split the string in a dom element into lines based on the space the dom element occupies in the document

export function splitLines(element) {
  const text = element.textContent;
  const words = text.split(" ");
  const lines = [];
  let currentLine = [];
  let currentWidth = 0;
  let currentHeight = 0;
  let currentLineHeight = 0;

  words.forEach(word => {
    const wordWidth = element.getBoundingClientRect().width;
    const wordHeight = element.getBoundingClientRect().height;

    if (wordWidth + currentWidth > element.parentElement.getBoundingClientRect().width) {
      lines.push(currentLine);
      currentLine = [];
      currentWidth = 0;
      currentHeight = wordHeight;
      currentLineHeight = wordHeight;
    }

    currentLine.push(word);
    currentWidth += wordWidth;
    currentHeight = Math.max(currentHeight, wordHeight);
    currentLineHeight = Math.max(currentLineHeight, wordHeight);
  });

  lines.push(currentLine);

  return { lines, height: currentHeight, lineHeight: currentLineHeight };
}

export function windowUndefinedReturnNull() {
  if (typeof window !== "undefined") {
    return window;
  }
  else {
    return null;
  }
}