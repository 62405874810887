//cute console message
export const CuteConsoleMessage = () => {
  "use strict";
  // console.clear();
  const i = ` padding: 0; 
              color:#000000; 
              line-height:20px; 
              font-size: 14px; 
              font-family:  'Helvetica Neue', sans-serif`,
      n = ["💪🏾", "❤️", "🙏🏾", "👼🏿", "✝️", "🔥", "🍎", "👨🏾‍💻", "🍕"],
      o = n[Math.floor(Math.random() * n.length)];
  console.log("%cMade with " + o + " \nby %cwww.tonyebrown.com    \n", i, "font-family:  'Helvetica Neue', sans-serif; font-weight:700; color: #000;")
}