/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { globalHistory as history } from '@reach/router'
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { Helmet } from 'react-helmet'
import "../styles/main.scss"
import Footer from "./Footer/index"
import AnimatedGrain from './Grain/AnimatedGrain'
import { CuteConsoleMessage } from './_helpers/CuteConsoleMessage'
import Header from "./header/index"

// This `location` prop will serve as a callback on route change
const Layout = ({ children, className, ref, smooth }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { location } = history;

  let app = useRef(null);

  useEffect(() => {
    CuteConsoleMessage();
  }, [location])

  // useEffect(() => {
  //   gsap.to(app, {duration: 0, css: { visibility: 'visible' } });
  // })

  return (
    <>
      <div id="container" className={className ? className : null} ref={el => app = el} /*ref={ref ? ref:null}*/>
        <Helmet>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@700&family=Shadows+Into+Light&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300&display=swap');
          </style>
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} location={location} />
        <main>{children}</main>
        {!location.pathname.startsWith("/blog/") && <AnimatedGrain />}
        <Footer location={location} />
      </div>
      {/* <Section desc="footer" dss={false}> */}
      {/* <Footer location={location} /> */}
      {/* </Section>       */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
