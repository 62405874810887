import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Animate = ({
  className = '',
  show = false,
  ease = 'ease',
  delay = 0,
  durationIn = 0.5,
  durationOut = 0.3,
  entrance = 'slide-in',
  exit = 'slide-out',
  children,
  style = {},
  visibleWhenNotAnimating = true,
  animationIteration = 1,
  fillMode = 'both',
}) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.id = 'animate-component-styles';

    styleElement.textContent = `
      @keyframes slide-in {
        0% {
          transform: translateY(100%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }

      @keyframes slide-out {
        0% {
          transform: translateY(0);
          opacity: 1;
        }
        100% {
          transform: translateY(100%);
          opacity: 0;
        }
      }
    `;

    document.head.appendChild(styleElement);

    return () => {
      document.getElementById('animate-component-styles')?.remove();
    };
  }, []);

  useEffect(() => {
    if (show) {
      setRender(true);
    } else {
      setTimeout(() => setRender(false), durationOut * 1000);
    }
  }, [show]);

  const animation = show
    ? `${durationIn}s ${Array.isArray(entrance) ? entrance.join(', ') : entrance} ${delay}s ${ease} ${animationIteration} ${fillMode}`
    : `${durationOut}s ${Array.isArray(exit) ? exit.join(', ') : exit} ${delay}s ${ease} ${animationIteration} ${fillMode}`;

  return (
    render && (
      <div
        style={{ animation, visibility: render ? 'visible' : 'hidden', ...style }}
        className={`${className} animated-component`}
        onAnimationEnd={() => { }}
      >
        {children}
      </div>
    )
  );
};

Animate.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  ease: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  delay: PropTypes.number,
  durationIn: PropTypes.number,
  durationOut: PropTypes.number,
  entrance: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  exit: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  visibleWhenNotAnimating: PropTypes.bool,
  animationIteration: PropTypes.number,
  fillMode: PropTypes.string,
};

export default Animate;
