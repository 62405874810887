import React from "react";
// import "../styles/components/_sections.scss"

function Section(props) {
  return (
    <section
      className={`section 
        ${props.desc ? props.desc : ""}
        ${props.hero ? "hero" : ""}
      `}
      id={props.desc}
      data-scroll-section={props.noScroll ? null : props.dss ? props.dss : true}
      data-scroll-direction={props.noScroll ? null : props.desc === "portfolio-wrapper" ? "vertical" : "horizontal"}
    >
      {props.children}
    </section>
  );
}

Section.propTypes = {};

export default Section;
