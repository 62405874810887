import { globalHistory as history } from "@reach/router";
import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  createAction,
  useKBar,
  useMatches
} from "kbar";
import React, { useEffect, useState } from 'react';
import "../../styles/components/CommandBar.scss";

export const actions = [
  {
    id: "homeAction",
    name: "Home",
    shortcut: ["h"],
    keywords: "back",
    section: "Navigation",
    perform: () => history.push("/"),
    icon: <HomeIcon />,
    subtitle: "Subtitles can help add more context.",
  },
  {
    id: "docsAction",
    name: "Docs",
    shortcut: ["g", "d"],
    keywords: "help",
    section: "Navigation",
    perform: () => history.push("/docs"),
  },
  {
    id: "contactAction",
    name: "Contact",
    shortcut: ["c"],
    keywords: "email hello",
    section: "Navigation",
    perform: () => window.open("mailto:bzllbrown@gmail.com", "_blank"),
  },
  {
    id: "twitterAction",
    name: "Twitter",
    shortcut: ["g", "t"],
    keywords: "social contact dm",
    section: "Navigation",
    perform: () => window.open("https://twitter.com/truevined", "_blank"),
  },
  createAction({
    name: "Github",
    shortcut: ["g", "h"],
    keywords: "sourcecode",
    section: "Navigation",
    perform: () => window.open("https://github.com/timc1/kbar", "_blank"),
  }),
];

function HomeIcon() {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m19.681 10.406-7.09-6.179a.924.924 0 0 0-1.214.002l-7.06 6.179c-.642.561-.244 1.618.608 1.618.51 0 .924.414.924.924v5.395c0 .51.414.923.923.923h3.236V14.54c0-.289.234-.522.522-.522h2.94c.288 0 .522.233.522.522v4.728h3.073c.51 0 .924-.413.924-.923V12.95c0-.51.413-.924.923-.924h.163c.853 0 1.25-1.059.606-1.62Z"
        fill="var(--foreground)"
      />
    </svg>
  );
}

function RenderResults() {
  const { results } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <><div className="kbar-listbox-item-result">{item}</div>
          </>
        ) : (
          <>
            <div className="kbar-listbox-item-result"
            >
              {item.name}
            </div>
          </>
        )
      }
    />
  );
}

function CommandBar() {
  const [open, setOpen] = useState(false);
  const { query } = useKBar();

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e) => {
      if (e.key === '/' || (e.key === 'k' && (e.metaKey || e.ctrlKey))) {
        e.preventDefault()
        setOpen((open) => !open)
        query.toggle()
      }
      if (e.key === 'Escape') {
        setOpen(false)
        query.toggle()
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  return (<>
    <span onClick={() => query.toggle()} className={`menu-circle command ${open ? 'active' : ''}`}>
    </span>
    <KBarPortal>
      <KBarPositioner className="command-bar-container">
        <KBarAnimator className="command-bar">
          <KBarSearch />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  </>
  );
}

export default CommandBar;
