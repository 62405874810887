import { MDXProvider } from '@mdx-js/react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { useEffect, useReducer, useState } from 'react';
import ImageVideo from './ImageVideo';

function PortfolioWrapperContent(props) {

  const [title, setTitle] = useState(props.title || "Tonye's Portfolio")
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    if (title !== props.title) {
      forceUpdate();
    }
    setTitle(props.title);

  }, [props.title]);

  function splitMulti(str) {
    const tokens = ['-', '&']
    let tempChar = tokens[0]; // We can use the first token as a temporary join character
    for (let i = 1; i < tokens.length; i++) {
      str = str.split(tokens[i]).join(tempChar);
    }
    str = str.split(tempChar);
    return str;
  }

  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          allMdx(
            filter: {fileAbsolutePath: {regex: "/portfolio/"}, frontmatter: {category: {eq: "portfolio"}}}
          ) {
            nodes {
              id
              excerpt
              fields {
                slug
              }
              timeToRead
              frontmatter {
                category
                date(formatString: "MMMM DD, YYYY")
                title
                description
                tags
                featuredImage {
                  childImageSharp {
                    gatsbyImageData(aspectRatio: 1.5)
                  }
                  name
                }
                featured
                videoSourceURL {
                  publicURL
                }
              }
              body
            }
          }
        }      
      `}
      render={data => {
        console.log('data:', data)
        const body = data.allMdx.nodes.find(
          node => node.frontmatter.title === props.title
        )
        console.log('data: body:', body)

        const next = data.allMdx.nodes.find(
          node => {
            // console.log("edge", edge)
            if (node.frontmatter.title === props.title) {
              if (node.next !== null) {
                return node;
              }
              else if (node.previous !== null) {
                return node;
              }
            }
          }
        )

        console.log('data: next:', next)


        // get the next mdx post from graphql
        // const next = data.allMdx.edges.find(
        //   edge => edge.frontmatter.title === props.next
        // )

        // console.log("data", data);
        // console.log("body", body);
        // console.log("next", next);
        // console.log("props ", props);

        if (!body) {
          return null
        }

        // const ImageVideo = props.imageVideo;
        const shortcodes = { ImageVideo }

        return (
          <>
            <h1 data-scroll data-scroll-speed="2">
              {title &&
                title.split(/,| |&|-/).map((item, index) => {
                  // title.split('&').join('&,').split(' ').join(' ,').split('-').join('-,').split(',').map((item, index) => {
                  return (
                    <span className="line" key={index}>
                      <span className="line-inner">{item}</span>
                    </span>
                  )
                })
              }
            </h1>

            {(body.frontmatter.tags && body.frontmatter.category) &&
              <div className="meta-data line">
                {body.frontmatter.tags && <>
                  <span className="line-inner">{body.frontmatter.category}, {body.frontmatter.tags.join(', ').toString()}</span>
                </>
                }
                <span className="scroll">(Scroll Down)</span>
              </div>
            }

            <div data-scroll data-scroll-speed="3" data-scroll-delay="0.08" className="position-relative overflow-hidden">
              <ImageVideo
                imageSrc={body?.frontmatter?.cover}
                videoSrc={body?.frontmatter?.videoSourceURL?.publicURL}
                alt={body?.frontmatter?.title}
              />
            </div>
            <MDXProvider components={shortcodes}>
              <MDXRenderer
                image={body?.frontmatter?.featuredImage}
                video={body?.frontmatter?.videoSourceURL?.publicURL}
              >
                {body.body}
              </MDXRenderer>
            </MDXProvider>

            <h1 className="recommended-header">
              <span className="line">
                <span className="line-inner">Some Other</span>
              </span>
              <span className="line">
                <span className="line-inner">Projects</span>
              </span>
            </h1>
            <div className="recommended row">
              {next.next && (
                <div className="next-portfolio col-md-6">
                  <Link to={"/portfolio" + next.next.fields.slug}>
                    <div className="next-portfolio-img">
                      <GatsbyImage
                        image={getImage(next.next.frontmatter.cover)}
                        alt={next.next.frontmatter.title}
                        loading="lazy"
                      />
                    </div>
                  </Link>
                  <Link className="link" to={"/portfolio" + next.next.fields.slug}>
                    <i className="recommended">{next.next.frontmatter.title}</i>
                  </Link>
                </div>
              )}
              {next.previous && (
                <div className="next-portfolio col-md-6">
                  <Link to={"/portfolio" + next.previous.fields.slug}>
                    <div className="next-portfolio-img">
                      <GatsbyImage
                        image={getImage(next.previous.frontmatter.cover)}
                        alt={next.previous.frontmatter.title}
                        loading="lazy"
                      />
                    </div>
                  </Link>
                  <Link className="link" to={"/portfolio" + next.previous.fields.slug}>
                    <i className="recommended">{next.previous.frontmatter.title}</i>
                  </Link>
                </div>
              )}
            </div>
          </>
        );
      }}
    />
  )
}

export default PortfolioWrapperContent
