import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

function StoriesCircle(props) {
  const src = props.img ? props.img : "/images/tonye_gradient.png";

  // if (!props.visible) return null; // TODO: add back later

  return (
    <div className={`stories-circle
      ${props.home ? "home" : ""}
      ${props.inHeader ? "" : "body"}`
    }

    >
      <div className={`circle-inner 
        ${props.watched ? "watched" : ""} 
        ${props.link ? "link" : ""}
      `}>
        {!props.link ?
          <StaticImage
            src="./tonye.png"
            height={191}
            width={191}
            className="circle-inner-img"
            objectFit="cover"
            objectPosition="bottom"
            quality={100}
          />
          :
          <Link to="/stories" className="circle-inner-link">& many more</Link>
        }
      </div>
      {props.text && !props.link && <span className="name">{props.text}</span>}
    </div>
  )
}

export default StoriesCircle
